import { FLOWPAGE_PATHS, PLATFORM_NAV_PATHS } from '@dtx-company/true-common/src/constants/layout'
import { Routes } from '@dtx-company/true-common/src/constants/routes'
import { theme } from '@dtx-company/design-system/src'
import { useAuthState } from '../useAuthState'
import { useMediaQuery } from '@mui/material'
import { useRouter } from 'next/router'

const routesWithoutBottomNav = [Routes.UNVERIFIED_ASSET]

export const useShouldShowBottomMobileNavBar = (): boolean => {
  const { isAuthenticated } = useAuthState()
  const router = useRouter()
  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down('md'))

  const isOnAuthRoute = router.pathname === Routes.SIGN_IN || router.pathname === Routes.SIGN_UP
  const isOnHomepage = router.pathname === Routes.INDEX

  const currentPageIsPlatformRoute = PLATFORM_NAV_PATHS.some(route => route === router.pathname)

  const isOnListedFlowpageRoute = FLOWPAGE_PATHS.some(route => router.pathname === route)
  const isOnUnlistedFlowpageRoute = isOnAuthRoute && router.query.flowpage === 'true'
  const isFlowpageRoute = isOnListedFlowpageRoute || isOnUnlistedFlowpageRoute

  const isOnRouteWithoutBottomNav = routesWithoutBottomNav.some(route => router.pathname === route)

  const shouldShowBottomNavBar =
    isAuthenticated &&
    currentPageIsPlatformRoute &&
    isMobileOrTablet &&
    !isFlowpageRoute &&
    !isOnHomepage &&
    !isOnRouteWithoutBottomNav

  return shouldShowBottomNavBar
}
